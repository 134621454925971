<template>
  <div class="d-flex flex-column flex-root">
    <div
            class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-5 p-sm-15">
      <div class="d-flex justify-content-center">
        <img :src="backgroundImage" class="img-fluid w-50" alt="background Image">
      </div>
      <!-- begin:: Content -->
      <h1 class="font-size-sm-100 font-weight-boldest text-dark-75 mt-5 text-center text-FFB803" style="font-size: 150px;">
        403
      </h1>
      <p class="font-weight-light text-center text-0C6F58" style="font-size: 40px !important;">
        {{$t('errors.you_dont_have_permission')}}
      </p>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/sajlha-logo-error.png";
    }
  }
};
</script>
